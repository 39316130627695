<template>
  <ol-map
    :loadTilesWhileAnimating="true"
    :loadTilesWhileInteracting="true"
    style="height: 100%"
  >
    <ol-view
      ref="view"
      :center="center"
      :rotation="rotation"
      :zoom="zoom"
      :projection="projection"
    />

    <ol-fullscreen-control />

    <ol-zoom-control />

    <ol-layerswitcher-control v-if="1 > 0" />

    <ol-zoomtoextent-control tipLabel="World" />

    <ol-tile-layer title="OSM">
      <ol-source-osm />
    </ol-tile-layer>

    <ol-control-bar>
      <ol-printdialog-control />
    </ol-control-bar>

    <ol-context-menu-control />


    
    <ol-layer-group :opacity="0.4" title="Telenor Maritime">


      <ol-vector-layer>
        <ol-source-vector :url="urlTelenor120" :format="geoJson"> </ol-source-vector>
        <ol-style>
          <ol-style-stroke color="rgba(0,128,255,1)" :width="2"></ol-style-stroke>
          <ol-style-fill color="rgba(0,128,255,1)"></ol-style-fill>
        </ol-style>
      </ol-vector-layer>

      <ol-vector-layer title="Telenor Maritime 110dB">
        <ol-source-vector :url="urlTelenor110" :format="geoJson"> </ol-source-vector>
        <ol-style>
          <ol-style-stroke color="rgba(0,255,255,1)" :width="2"></ol-style-stroke>
          <ol-style-fill color="rgba(0,255,255,1)"></ol-style-fill>
        </ol-style>
      </ol-vector-layer>

      <ol-vector-layer title="Telenor Maritime 100dB">
        <ol-source-vector :url="urlTelenor100" :format="geoJson"> </ol-source-vector>
        <ol-style>
          <ol-style-stroke color="rgba(128,255,128,1)" :width="2"></ol-style-stroke>
          <ol-style-fill color="rgba(128,255,128,1)"></ol-style-fill>
        </ol-style>
      </ol-vector-layer>

      <ol-vector-layer title="Telenor Maritime 90dB">
        <ol-source-vector :url="urlTelenor90" :format="geoJson"> </ol-source-vector>
        <ol-style>
          <ol-style-stroke color="rgba(255,255,128,1)" :width="2"></ol-style-stroke>
          <ol-style-fill color="rgba(255,255,128,1)"></ol-style-fill>
        </ol-style>
      </ol-vector-layer>

      <ol-vector-layer title="Telenor Maritime 80dB">
        <ol-source-vector :url="urlTelenor80" :format="geoJson"> </ol-source-vector>
        <ol-style>
          <ol-style-stroke color="rgba(255,128,64,1)" :width="2"></ol-style-stroke>
          <ol-style-fill color="rgba(255,128,64,1)"></ol-style-fill>
        </ol-style>
      </ol-vector-layer>
    </ol-layer-group>



    <ol-tile-layer>
      <ol-source-tile-wms
        :url="url"
        layers="npdfacility_latest"
        serverType='geoserver'
        transition=0
      ></ol-source-tile-wms>
    </ol-tile-layer>



    <ol-vector-layer title="AIS">
      <ol-source-vector>
        <ol-feature v-for="index in res.data.features" :key="index">
          <ol-geom-point :coordinates="ref(convertCoordinates(index.geometry.coordinates[0], index.geometry.coordinates[1]))">
          </ol-geom-point>
          <ol-style>
            <ol-style-circle :radius="10">
              <ol-style-fill color="blue"></ol-style-fill>
              <ol-style-stroke color="red" :width="2"></ol-style-stroke>
              <ol-style-text font="14px sans-serif" :text="index.properties.name + ' (' + index.properties.speedOverGround + 'kt) ['+index.properties.msgtime.substring(11, 19)+']'" offsetX="15" textAlign="left">
              </ol-style-text>
            </ol-style-circle>
          </ol-style>
        </ol-feature>
      </ol-source-vector>

    </ol-vector-layer>

  </ol-map>

</template>

<script setup>
import { ref, inject, onMounted } from "vue";
import axios from "axios";

let url2 = new URL(window.location.href);
let token = url2.searchParams.get("token");

//console.log(token);
const url = ref("https://geo.barentswatch.no/geoserver/bw/ows?VERSION=1.3.0");

//onCreated(() => {
//const res = await axios.get('https://fleetmap-flask-backend.azurewebsites.net/ais');
//const res = await axios.get('http://localhost:5000/ais');

var res = null;
if(token == 'JzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6Ikpva') {
  res = await axios.get(process.env.VUE_APP_API_URL + '/aiscache');
} else {
  res = {};
  res.data = {};
  res.data.params = {};
}

//console.log(res.data.features);
//}
//const items = ref([{ lon: 5, lat: 58, name: 'BBB' }, { lat: 59, lon: 6, name: 'CCC' }]);

/*
import "ol-layerswitcher/dist/ol-layerswitcher.css";
import LayerSwitcher from "ol-layerswitcher";

const layerSwitcher = new LayerSwitcher({
      reverse: true,
      groupSelectStyle: "group",
    });
    const map = ref(null);

    //TODO onmount?
    //https://codesandbox.io/p/sandbox/demo-map-addcontrol-x4wwus?file=%2Fsrc%2Fcomponents%2FComp.vue%3A56%2C7-56%2C47
    map.value.map.addControl(layerSwitcher);
*/
//import { Fill, Stroke, Style } from "ol/style";

/*
import LayerSwitcher from 'ol-layerswitcher';
const layerSwitcher = new LayerSwitcher({
    reverse: true,
    groupSelectStyle: 'group'
  });
  this.olmap.addControl(layerSwitcher);
*/


function convertCoordinates(lon, lat) {
  var x = (lon * 20037508.34) / 180;
  var y = Math.log(Math.tan(((90 + lat) * Math.PI) / 360)) / (Math.PI / 180);
  y = (y * 20037508.34) / 180;
  return [x, y];
}

const center = ref(convertCoordinates(10, 65));

//const projection = ref("EPSG:4326");
const projection = ref("EPSG:3857");
const zoom = ref(5);
const rotation = ref(0);

//generic
const format = inject("ol-format");
const geoJson = new format.GeoJSON();

//AIS
//const urlAis = ref("https://fleetmap-flask-backend.azurewebsites.net/ais");

//Telenor Maritime
const urlTelenor120 = ref("https://fleetmap-flask-backend.azurewebsites.net/telenor120");
const urlTelenor110 = ref("https://fleetmap-flask-backend.azurewebsites.net/telenor110");
const urlTelenor100 = ref("https://fleetmap-flask-backend.azurewebsites.net/telenor100");
const urlTelenor90 = ref("https://fleetmap-flask-backend.azurewebsites.net/telenor90");
const urlTelenor80 = ref("https://fleetmap-flask-backend.azurewebsites.net/telenor80");

//const vesselName = "AAA";
/*
    <ol-vector-layer>
      <ol-source-vector :url="urlTelenor120" :format="geoJson"> </ol-source-vector>
      <ol-style>
        <ol-style-stroke color="rgba(0,128,255,1)" :width="2"></ol-style-stroke>
        <ol-style-fill color="rgba(0,128,255,1)"></ol-style-fill>
      </ol-style>
    </ol-vector-layer>
    */



/*
function styleAis() {
      const style = new Style({
        stroke: new Stroke({
          color: "red",
          width: 3,
        }),
        fill: new Fill({
          color: "rgba(0, 0, 255, 0.4)",
        }),
      });
      return style;
    }
*/
/*
export default {
  setup() {
    //
  }
}

async created(){

}
*/
onMounted(() => {
  if(process.env.NODE_ENV == 'development') {
    console.log('NODE_ENV='+process.env.NODE_ENV);
  }
});



</script>