<template>
  <suspense>
  <MapView/>
</suspense>
</template>

<script>
//import BackendTest from './components/BackendTest.vue'

  import MapView from './components/MapView.vue'
//import HelloWorld from './components/HelloWorld.vue'
//import HelloWorld from './components/AppDemo.vue'

export default {
  name: 'App',
  components: {
    //BackendTest,
    MapView,
    //HelloWorld,
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
</style>
